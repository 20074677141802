import TagFilters from '@components/common/TagFilters'
import { Button } from '@components/ui'
import cn from 'classnames'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC } from 'react'

export const LINKS = [
  {
    label: 'new arrivals',
    link: '/products/new-arrivals',
  },
  {
    label: 'bed sheets',
    link: '/products/bed-sheets',
  },
  {
    label: 'quilt covers',
    link: '/products/quilt-covers',
  },
  {
    label: 'fitted sheets',
    link: '/products/bed-sheets/fitted-sheets',
  },
  {
    label: 'blankets',
    link: '/products/blankets',
  },
  {
    label: 'sleep your way fabric guide',
    link: '/sleep-your-way',
  },
]

const Error404: FC = () => {
  const { locale } = useRouter()
  return (
    <>
      <div
        className={cn(
          'mx-auto w-full banner relative justify-center items-center overflow-hidden mb-32 hidden laptop:flex'
        )}
      >
        <Image src={`/images/404-desktop-v4.jpg`} alt="Sheet Society" layout="fill" objectFit="cover" />

        <h1 className=" w-full absolute top-1/2 left-1/2 heading-2 text-white errorTitle text-center">
          Looks like we didn’t get our 8 hours
        </h1>
      </div>
      <div className="relative flex laptop:hidden mb-32">
        <Image src={`/images/404-mobile.jpg`} alt="Sheet Society" width="1024" height="578" />

        <h1 className=" w-full absolute top-1/2 left-1/2 heading-2 text-white errorTitle text-center">
          Looks like we didn’t get our 8 hours
        </h1>
      </div>

      <div className="wrapper">
        <h3 className="heading-3 laptop:heading-5 pb-12 text-center">Something's not quite right with this page.</h3>
        <h5 className="body pb-24 text-center">Click one of these links to find what you're looking for.</h5>
        <div className="w-full laptop:wrapper display flex justify-center pb-32">
          <TagFilters items={LINKS} overflow="wrap" alignment="left" />
        </div>

        <div className="block pb-40 laptop:flex justify-center gap-16 laptop:pb-80">
          <Link href={`/${locale || ''}`} passHref legacyBehavior>
            <Button variant="primary" className="mb-16 laptop:mb-0">
              BACK TO HOMEPAGE
            </Button>
          </Link>
          {/* <Button variant="buttonLink">SEARCH FOR SOMETHING</Button> */}
        </div>
      </div>

      <style jsx>
        {`
          .banner {
            height: 55vh;
          }
          .errorTitle {
            transform: translate(-50%, -50%);
          }

          @media (max-width: 425px) {
            .banner {
              height: auto;
            }
          }
        `}
      </style>
    </>
  )
}

export default Error404
